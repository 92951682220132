module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SimGui.de","short_name":"SimGui.de","description":"The trouble-free sim racing buyers guide.","lang":"en","start_url":"/","background_color":"#79262d","theme_color":"#79262d","display":"minimal-ui","icon":"src/assets/images/favicon.svg","localize":[{"start_url":"/en/","lang":"en","name":"SimGui.de","short_name":"SimGui.de","description":"The trouble-free sim racing buyers guide."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"77e4842bded6ac351298174f050478e3"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"en","configPath":"/opt/build/repo/i18n/config.json"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"defaultLocale":"./i18n/en.json"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-217837298-1","head":false,"anonymize":true,"respectDNT":true,"defer":true,"storage":"none","storeGac":false,"allowAdFeatures":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
